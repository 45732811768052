import { AgoraWebClient } from "@/utils/agora-web-client";
import { eventRoomStore as globleventroomStore } from "@/stores/event";
import {
  MainLiveStreamId,
  INJECT_STREAM_ID,
  SECOND_INJECT_STREAM_ID,
  SCREEN_SHARE_STREAM_ID,
} from "@/utils/config";
import { useEventRoomState } from "@/containers/root-container";
import { agoraRTMClient, ChannelParams, ChatCmdType } from "@/utils/agora-rtm-client";
import { ESocketEvents } from "@/utils/api/sockets.enum";

export default function useSubscribeRTCEvent({
  eventRoomStore,
  emitEvent
}: any = globleventroomStore) {
  // const eventRoomState = useEventRoomState();
  const subscribeRTCEvent = (webClient: AgoraWebClient) => {
    webClient.rtc.on("user-joined", async ([remoteUser]) => {
      const streamID = remoteUser.uid;
      let tempRemoteStream = {
        audioTrack: null,
        videoTrack: null,
      } as any;

      const userRole = eventRoomStore.getUserRoleById(streamID);
      const details = {
        name:
          userRole == "multiconnection"
            ? await eventRoomStore.fetchUserDetails(
              eventRoomStore.state.me.channelName,
              streamID
            )
            : eventRoomStore.getUserNameById(streamID),
      };

      const urlParams = new URLSearchParams(window.location.search);
      const showAdminVideoTile = urlParams.get('show');
      console.log("showAdminVideoTile", showAdminVideoTile)

      if(eventRoomStore._state.me.info.role == 'admin'){
        if(showAdminVideoTile == '1'){
          emitEvent(ESocketEvents.ADMIN_TILE, {
            visit_id: eventRoomStore._state.me.channelName,
            value: true
          }); 
        } else {
          emitEvent(ESocketEvents.ADMIN_TILE, {
            visit_id: eventRoomStore._state.me.channelName,
            value: false
          });
        }
      }


      console.log("adding remote stream details ?????????: ", tempRemoteStream)
      eventRoomStore.addRemoteStreamDetails(
        streamID,
        tempRemoteStream,
        details,
        userRole
      );
    });

    webClient.rtc.on("user-left", ([user, reason]) => {
      handleStreamRemove(user.uid);
    });

    webClient.rtc.on("user-published", async ([remoteUser, mediaType]) => {
      const streamID = remoteUser.uid;
      //if (streamID !== eventRoomStore.state.me.uid) {
      await webClient.subscribe(remoteUser, mediaType);
      let streamTrack =
        mediaType === "video" ? remoteUser.videoTrack : remoteUser.audioTrack;
      let streamTrackType = mediaType === "video" ? "videoTrack" : "audioTrack";
      const userRole = eventRoomStore.getUserRoleById(streamID);
      const details = {
        name:
          userRole == "multiconnection"
            ? await eventRoomStore.fetchUserDetails(
              eventRoomStore.state.me.channelName,
              streamID
            )
            : eventRoomStore.getUserNameById(streamID),
      };
      eventRoomStore.updateRemoteDetails(
        streamTrackType,
        streamID,
        streamTrack,
        details
      );
      //});
    });
    webClient.rtc.on("user-unpublished", ([remoteUser, mediaType]) => {
      eventRoomStore.mute(remoteUser.uid, mediaType);
    });
  };

  const handleStreamRemove = (streamID: number) => {
    const pinid = Number(sessionStorage.getItem("pinid"));
    if (
      eventRoomStore.state.rtc.injectedStream &&
      +streamID === +eventRoomStore.state.rtc.injectedStream.streamID
    ) {
      eventRoomStore.removeInjectedStream();
    } else {
      eventRoomStore.removePeerUser(streamID);
      eventRoomStore.removeRemoteStream(streamID);
    }
    if (streamID === eventRoomStore.state.me.mainLiveStreamID) {
      eventRoomStore.updateLocalMe({
        mainLiveStreamID: 0,
      });
    }
    if (streamID === SCREEN_SHARE_STREAM_ID) {
      const grid = sessionStorage.getItem("grid");
      if (grid === "true") {
        eventRoomStore.updateGridView(true);
      } else {
        eventRoomStore.updateGridView(false);
      }
    }

    if (streamID === pinid) {
      // sessionStorage.setItem("pinid", "0");
      eventRoomStore.setMeAttr("mainLiveStreamID", 0);
    }
  };

  return {
    subscribeRTCEvent,
  };
}

import React, { useEffect, useMemo, useRef, useState } from "react";
import "./event.scss"; // TODO
import {
  eventRoomStore as globaleventRoomStore,
  EventRoomState,
} from "@/stores/event";
import { useEventRoomState } from "@/containers/root-container";
import { globalStore } from "@/stores/global";
import { AgoraWebClient } from "@/utils/agora-web-client";
import AgoraRTMClient from "@/utils/agora-rtm-client";
import { AgoraStreamSpec } from "@/utils/agora-rtc-client";
import { EVENTS_DETAIL, USER_ROLES } from "@/utils/config";
import useSubscribeRTMEvent from "@/hooks/use-subscribe-rtm-event";
import useSubscribeRTCEvent from "@/hooks/use-subscribe-rtc-event";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { useObserver } from "@/containers/root-container";
import DashBoardEvents from "@/components/dashboard-events/DashBoardEvents";
import { ApiUrls } from "@/utils/api/constant/api.constant";
import useSocket from "@/hooks/useSocket";

type ParamTypes = {
  user_role: any;
  meetingId: any;
  [key: string]: any;
};

export function EventPage({
  eventRoomStoreLocal,
  VisitDetails,
  children,
}: any) {
  let eventRoomStore: any;
  let eventRoomState: any;
  if (eventRoomStoreLocal) {
    eventRoomStore = eventRoomStoreLocal;
    eventRoomState = useObserver<EventRoomState>(eventRoomStoreLocal);
  } else {
    eventRoomStore = globaleventRoomStore;
    eventRoomState = useEventRoomState();
  }

  useEffect(() => {
    if (eventRoomStoreLocal) {
      eventRoomStore = eventRoomStoreLocal;
      eventRoomState = eventRoomStoreLocal._state;
    } else {
      eventRoomStore = globaleventRoomStore;
    }
  }, [eventRoomStoreLocal]);

  const { emitEvent } = useSocket();

  const { subscribeRTCEvent } = useSubscribeRTCEvent({ eventRoomStore, emitEvent });
  const { subscribeRTMEvent } = useSubscribeRTMEvent();
  const rtc = useRef<boolean>(false);
  const rtm = useRef<boolean>(false);

  const eventState = eventRoomStore.state;
  const me = eventState.me;
  const location = window.location;
  const searchParams = new URLSearchParams(location.search);
  const param = useParams<ParamTypes>();
  const userType: string =
    (searchParams.get("user_type") as string) || USER_ROLES.HOST;
  let userChannel = VisitDetails?.admin_link.replace(
    `${ApiUrls.channelUrl}`,
    ""
  );
  const channel: any = eventRoomStoreLocal ? userChannel : param.meetingId;
  const user_role: any = (param.user_role as any) || "guide";

  const getVisitDetails = async () => {
    const visitDetails = await eventRoomStore.fetchVisitDetails(channel);
    let authError = false;
    if (visitDetails?.status == 423) {
      window.location.href = "/error/invalid";
    } else if (visitDetails.status == 200) {
      const details = visitDetails.visit as any;
      if (details && details.user_role != "guide" && Cookies.get("token")) {
        /* Guide can't join residence/host visit */
        window.location.href = "/error/access-denied";
      }
    } else {
      if (Cookies.get("token")) {
        if (visitDetails?.status == 400) {
          //If guide tries to access/join other guide's meeting link
          window.location.href = "/error/access-denied";
        } else if (visitDetails?.status == 401) {
          authError = true;
        } else {
          eventRoomStore.logout();
        }
      } else {
        window.location.href = "/404";
      }
    }
    if (authError) {
      await getVisitDetails();
    }
    return visitDetails;
  };

  useEffect(() => {
    if (eventRoomState.me.channelName || !channel) return;
    if (!eventRoomStoreLocal) {
      globalStore.showLoading();
    }
    // update the state of room
    setTimeout(async () => {
      let details;
      if (eventRoomStoreLocal) {
        details = VisitDetails;
      } else {
        const visitDetails = await getVisitDetails();
        details = visitDetails?.visit as any;
      }
      //const visitDetails = await eventRoomStore.fetchVisitDetails(channel);
      // let details = visitDetails?.visit as any;
      if (details) {
        const user_id =
          details.user_role == "guide"
            ? parseInt(details?.guide_user_id)
            : details.user_role == "host"
            ? parseInt(details?.host_user_id)
            : details.user_role == "residence"
            ? parseInt(details?.residence_user_id)
            : details.user_role == "invitee"
            ? parseInt(details?.invitee.id)
            : details.user_role == "admin"
            ? parseInt(details?.admin_user_id)
            : details.user_role == "multiconnection"
            ? sessionStorage.getItem("userId")
              ? sessionStorage.getItem("userId")
              : await eventRoomStore.fetchUniqueId()
            : parseInt(details?.admin_user_id);
        /* If this is guide meeting link and is not logged in, redirect user to login page */
        if (details.user_role == "guide" && !Cookies.get("token")) {
          window.location.href = "/error/access-denied";
        } else if (details.user_role != "guide" && Cookies.get("token")) {
          /* Guide can't join residence/host visit */
          window.location.href = "/error/access-denied";
        } else if (
          ((details.user_role == "guide" ||
            details.user_role == "host") &&
            !eventRoomStore.canStartVisit(details.time_in_utc))
        ) {
          //Guide or host can't join the visit before 10 mins and residence can't join the direct meeting link if the visit has not been started by guide/host
          window.location.href = "/visit/" + channel;
        } else {
          const eventRoomState: EventRoomState = {
            ...eventState,
            me: {
              ...me,
              uid: user_id,
              role: userType === USER_ROLES.HOST ? 1 : 0,
              channelName: details?.id,
              publishLocalStream: userType === USER_ROLES.HOST ? true : false,
              info: {
                uid: user_id,
                name:
                  details.user_role == "guide"
                    ? details?.guide_username
                    : details.user_role == "host"
                    ? details?.host_username
                    : details.user_role == "residence"
                    ? details?.residence_name
                    : details.user_role == "invitee"
                    ? details?.invitee.name
                    : details.user_role == "admin"
                    ? details?.admin_user_name
                    : sessionStorage.getItem("username")
                    ? sessionStorage.getItem("username")
                    : "",
                role: details.user_role.toLowerCase() || "admin",
                profile_pic: "",
                guide: {
                  id: details?.guide_user_id,
                  name: details?.guide_username ? details?.guide_username : "",
                  profile_pic: "",
                  profile_url: "",
                  userRole: "guide",
                },
                host: {
                  id: details?.host_user_id,
                  name: details?.host_username ? details?.host_username : "",
                  userRole: "host",
                },
                residence: {
                  id: details?.residence_user_id,
                  name: details?.residence_name ? details?.residence_name : "",
                  userRole: "residence",
                },
                admin: {
                  id: details?.admin_user_id,
                  name: details?.admin_user_name
                    ? details?.admin_user_name
                    : "",
                  userRole: "admin",
                },
                invitees: details?.invitees,
                is_multiconnection: details?.is_multiconnection,
              },
            },
            is_residence_silent: details.is_residence_silent,
            is_noise_cancellation_on: details.is_noise_cancellation_on,
            is_technical_visit: details.is_technical_visit,
            pinId: details?.guide_user_id,
            waitingList: details.waiting_residence,
          };
          eventRoomStore.updateState(eventRoomState);
        }
      }
    }, 0);
    globalStore.stopLoading();
  }, [eventRoomState.me.channelName, eventRoomStoreLocal]);

  const publishLock = useRef<boolean>(false);

  // Publish local stream
  const publishLocalStream = () => {
    if (!rtcJoined || publishLock.current || !uid) return;
    const webClient = eventRoomStore.rtcClient as AgoraWebClient;
    if (webClient.published) return;
    let streamSpec: AgoraStreamSpec = {
      streamID: uid,
      video: true,
      audio: true,
      screen: false,
      optimizationMode: "motion",
      isInjectedStream: false,
    };

    if (window.sessionStorage.getItem("cameraId") != null) {
      streamSpec.cameraId = window.sessionStorage.getItem("cameraId") as any;
    }

    if (window.sessionStorage.getItem("microphoneId") != null) {
      streamSpec.microphoneId = window.sessionStorage.getItem(
        "microphoneId"
      ) as any;
    }

    // if(window.sessionStorage.getItem("speakerId")!=null && eventRoomState.me.uid != INJECT_STREAM_ID){
    //   streamSpec.setAudioOutput = window.sessionStorage.getItem("speakerId") as any;
    // }
    if (!publishLock.current) {
      publishLock.current = true;
      Promise.all([webClient.publishLocalStream(streamSpec)])
        .then((res: any[]) => {
          console.log("[agora-web] any: ", res[0], res[1]);
          console.log("[agora-web] publish local stream");
        })
        .catch(console.warn)
        .finally(() => {
          publishLock.current = false;
          globalStore.stopLoading();
        });
    }
  };

  // getting some basic info from eventRoomState
  const { rtcJoined, rtmJoined, uid, role } = useMemo(() => {
    return {
      rtcJoined: eventRoomState.rtc.joined,
      rtmJoined: eventRoomState.rtm.joined,
      uid: eventRoomState.me.uid,
      role: eventRoomState.me.role,
    };
  }, [
    eventRoomState.rtc.joined,
    eventRoomState.rtm.joined,
    eventRoomState.me.uid,
    eventRoomState.me.role,
    eventRoomStoreLocal,
  ]);

  // Handle Publishing Local Stream
  useEffect(() => {
    if (
      eventRoomState.rtc.joined &&
      eventRoomState.me.publishLocalStream &&
      eventRoomState.me.info.role !== "invitee"
    ) {
      if (eventRoomState.me.info.role == "admin" && eventRoomStoreLocal) {
        publishLock.current = false;
        globalStore.stopLoading();
      } else {
        publishLocalStream();
      }
    } else if (eventRoomState.me.info.role == "invitee") {
      publishLock.current = false;
      globalStore.stopLoading();
    }
  }, [
    eventRoomState.rtc.joined,
    eventRoomState.me.uid,
    eventRoomState.me.publishLocalStream,
    eventRoomStoreLocal,
  ]);
  const [height, setheight] = useState<any>();
  useEffect(() => {
    setheight(window.innerHeight);
    const handleResize = () => {
      setheight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, []);

  // this is triggers when leaving a event room type
  useEffect(() => {
    return () => {
      if (!eventRoomStoreLocal) {
        globalStore.showLoading();
      }
      eventRoomStore
        .exitAll()
        .then(() => {
          //leaveFanWall()
        })
        .catch(console.warn)
        .finally(() => {
          rtc.current = false;
        });
    };
  }, [location]);

  // when user role change from host to audience
  useEffect(() => {
    if (role === 0) return;
    const rtcClient = eventRoomStore.rtcClient;
    const webClient = rtcClient as AgoraWebClient;
    if (!webClient.published) return;
    Promise.all([webClient.unpublishLocalStream()])
      .then(() => {
        console.log("[agora-web] unpublish local stream");
      })
      .catch(console.warn);
  }, [role, eventRoomStoreLocal]);

  const participantsDetails = async () => {
    await eventRoomStore.SubmitParticipantdetails(
      sessionStorage.getItem("visit_id"),
      sessionStorage.getItem("username"),
      sessionStorage.getItem("userId"),
      sessionStorage.getItem("userole")
    );
  };

  // Joining the RTC Client and subscribing to client events
  useEffect(() => {
    if (
      !eventRoomState.appID ||
      !eventRoomState.me.channelName ||
      !eventRoomState.hasStarted
    )
      return;

    const webClient = eventRoomStore.rtcClient as AgoraWebClient;
    if (rtcJoined || rtc.current) {
      return;
    }
    if (!eventRoomStoreLocal) {
      globalStore.showLoading();
    }
    rtc.current = true;
    const rtcToken = eventRoomStore
      .getAgoraTokens(eventRoomState.me.channelName, eventRoomState.me.uid)
      .then((rtcToken: any) => {
        webClient
          .joinChannel({
            uid: +eventRoomState.me.uid,
            channel: eventRoomState.me.channelName,
            token: rtcToken,
            dual: true,
            appId: eventRoomState.appID,
            role: eventRoomStoreLocal ? "audience" : "host",
            emitEvent
          })
          .then((data: any) => {
            console.log("[agora-web] join channel", data);
            eventRoomStore.setRTCJoined(true);
            eventRoomStore.setRTCToken(rtcToken);
            // eventRoomStore.updateState(eventRoomState);
            //eventRoomStore.setMeAttr("uid", data?.userId as number);
            // subscribe to RTC events after 2 second. Assuming that If there is any audience interaction going on then will get response in 2 sec.
            subscribeToClientEvents();
            if (eventRoomState.me.info.role == "multiconnection") {
              participantsDetails();
            }
          })
          .catch((e) => {
            alert(e);
            globalStore.stopLoading();
          })
          .finally(() => {
            rtc.current = false;
            if (role === 0) globalStore.stopLoading();
          });
      });
  }, [
    eventRoomState.me.channelName,
    eventRoomState.appID,
    eventRoomState.hasStarted,
    eventRoomStoreLocal,
  ]);

  // Joining the RTM channel
  useEffect(() => {
    if(eventRoomState.me.info.role === 'invitee' && !eventRoomState.hasStarted) {
      return
    }
    console.log(
      eventRoomState.isLive,
      rtmJoined,
      rtm.current,
      eventRoomState.me.uid,
      "state details"
    );
    if (
      rtmJoined ||
      rtm.current ||
      !eventRoomState.me.uid ||
      eventRoomStoreLocal
      // !sessionStorage.getItem("visitStarted")
    ) {
      return
    }
    rtm.current = true;
    //const rtmid = param.meetingId;
    const rtmToken = eventRoomStore
      .getAgoraTokens(
        eventRoomState.me.channelName,
        eventRoomState.me.uid,
        "rtm"
      )
      .then((rtmToken: any) => {
        //return response
        // WARN: IF YOU ENABLED APP CERTIFICATE, PLEASE SIGN YOUR TOKEN IN YOUR SERVER SIDE AND OBTAIN IT FROM YOUR OWN TRUSTED SERVER API
        eventRoomStore
          .loginAndJoin(rtmToken)
          .then(() => {
            // TEMP WORK JUST TO TEST
            // Join Fan Wall
            //  joinFanWall()
            console.log('joined rtm sucessfully');
          })
          .catch((err: any) => {
            // globalStore.showToast({
            //   type: 'rtmClient',
            //   message: t('toast.login_failure'),
            // });
            //history.push("/");
            console.warn(err);
          })
          .finally(() => {
            rtm.current = false;
            // lock.current = false;
          });
      });
  }, [
    eventRoomState.me.uid,
  eventRoomState.me.info.role,
  eventRoomState.hasStarted,
  rtmJoined,
  eventRoomStoreLocal,
    // sessionStorage.getItem("visitStarted"),
  ]);

  // Subscribing RTM events
  useEffect(() => {
    if (!eventRoomState.rtm.joined) return;
    const rtmClient = eventRoomStore.rtmClient as AgoraRTMClient;
    subscribeRTMEvent(rtmClient);
    return () => {
      rtmClient.removeAllListeners();
    };
  }, [eventRoomState.rtm.joined, eventRoomStoreLocal]);

  // Subscribing RTC Client event
  const subscribeToClientEvents = () => {
    const webClient = eventRoomStore.rtcClient as AgoraWebClient;
    subscribeRTCEvent(webClient);
  };

  // update audience list when a remote users list updated
  useEffect(() => {
    if (eventRoomState.me.role === 1 && eventRoomStore.state.me.channelName) {
      eventRoomStore.getMembersList(eventRoomStore.state.me.channelName);
    }
  }, [eventRoomState.rtc.users, eventRoomStoreLocal]);
  useEffect(() => {
    if (eventRoomState.rtc.isAutoPlayFailed) {
      globalStore.showToast({
        type: EVENTS_DETAIL.AUTOPLAY_CLICK_TOAST_TYPE,
        message: EVENTS_DETAIL.AUTOPLAY_TOAST_MSG,
        config: {
          hideProgressBar: true,
          closeOnClick: true,
          type: "error",
          autoClose: EVENTS_DETAIL.AUTOPLAY_CLICK_TIMEOUT,
        },
      });
    } else {
      globalStore.hideToast(EVENTS_DETAIL.AUTOPLAY_CLICK_TOAST_TYPE);
    }
  }, [eventRoomState.rtc.isAutoPlayFailed]);

  return (
    <div
      style={{
        height: `${!eventRoomStoreLocal && height}px`,
        width: `${eventRoomStoreLocal && "45%"}`,
      }}
      className={`event-room-page wrapper ${
        eventRoomStoreLocal && "host-dashboard-page-info"
      }`}
    >
      {eventRoomStoreLocal ? (
        <DashBoardEvents
          VisitDetails={VisitDetails}
          eventRoomStoreLocal={eventRoomStoreLocal}
        />
      ) : (
        children
      )}
    </div>
  );
}
